import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'

export default ({ data }) => (
  <Layout pageTitle='Sidan hittades inte' siteImage={data.header}>
    <Header fluidImage={data.header.childImageSharp.fluid} />
    <oma-grid-row>
      <section className='section'>
        <h1 className='heading page__heading'>Sidan hittades inte</h1>
        <p>
          <i
            class='fas fa-level-up fa-2x fa-flip-horizontal'
            style={{ position: 'relative', bottom: '.5rem' }}
          ></i>{' '}
          Denna sidan finns inte längre. Du hittar information om oss och våra
          tjänster ovan.
        </p>
        <p>
          <i
            class='fas fa-level-down fa-2x fa-flip-horizontal'
            style={{ position: 'relative', top: '1.3rem' }}
          ></i>{' '}
          Eller information om denna sidan nedan.
        </p>
      </section>
    </oma-grid-row>
    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "sheep.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
